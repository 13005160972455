import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import surveyPagination from "../assets/images/survey-pagination-3.png";
import TermsModal from "./termsModal";
import { isGuid } from "../utils/utils";
import axios from "axios";
import $ from "jquery";

export default class DonePage extends Component {
  constructor(props) {
    super(props);

    window.scrollTo(0, 0);

    this.state = {
      chkTerms: false,
      reviewSites: [],
      clientId: this.props.urlData.clientId,
      sitesContent: "",
      navigate: this.props.urlData.navigate,
      donePageClick: false,
    };

    this.handleChangeChk = (evt) => {
      this.ClickEnablePartnerShare(evt.target.checked);
      this.setState({
        chkTerms: evt.target.checked,
      });
    };
  }

  async GetDealerSites() {
    const host = process.env.REACT_APP_API_BASE_URL;
    const consumerId = this.props.urlData.consumerId;
    const surveyEmailId = this.props.urlData.surveyEmailId;
    const clientId = this.props.urlData.clientId;

    if (isGuid(consumerId) && isGuid(surveyEmailId)) {
      const headers = {
        "DAS-Guest-Subscription-Key":
          process.env.REACT_APP_API_SUBSCRIPTION_KEY,
      };

      await axios
        .get(
          `${host}/donepagesites?ConsumerId=${consumerId}&SurveyEmailId=${surveyEmailId}&ClientId=${clientId}`,
          { headers }
        )
        .then((res) => {
          this.setState({
            reviewSites: res.data.reviewSiteList,
            sitesContent: res.data.sitesContent,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  async GetEnablePartnerShareInfo() {
    const host = process.env.REACT_APP_API_BASE_URL;
    const consumerId = this.props.urlData.consumerId;
    const surveyEmailId = this.props.urlData.surveyEmailId;
    const clientId = this.props.urlData.clientId;

    await axios
      .get(
        `${host}/surveyresponseinfo?ConsumerId=${consumerId}&SurveyEmailId=${surveyEmailId}&ClientId=${clientId}`,
        {
          headers: {
            "DAS-Guest-Subscription-Key":
              process.env.REACT_APP_API_SUBSCRIPTION_KEY, //the guest subscription key.
          },
        }
      )
      .then((res) => {
        const surveyResponseInfoData = res.data;

        $("#terms-checkbox").prop(
          "checked",
          surveyResponseInfoData.enablePartnerShare
        );

        this.setState({
          chkTerms: surveyResponseInfoData.enablePartnerShare,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async ClickOnReviewSite(reviewSiteId, redirectUrl) {
    const host = process.env.REACT_APP_API_BASE_URL;
    const consumerId = this.props.urlData.consumerId;
    const surveyEmailId = this.props.urlData.surveyEmailId;
    const clientId = this.props.urlData.clientId;

    if (isGuid(consumerId) && isGuid(surveyEmailId)) {
      this.setState({
        donePageClick: true,
      });

      const body = {};
      const headers = {
        "DAS-Guest-Subscription-Key":
          process.env.REACT_APP_API_SUBSCRIPTION_KEY,
      };

      await axios
        .post(
          `${host}/clickonreviewsite?ConsumerId=${consumerId}&SurveyEmailId=${surveyEmailId}&ReviewSiteId=${reviewSiteId}&ClientId=${clientId}`,
          body,
          { headers }
        )
        .then((res) => {
          setTimeout(function () {
            window.open(redirectUrl).focus();
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  async ClickEnablePartnerShare(enablePartnerShare) {
    $("#loaderSpinnerEnablePartnerShare").show();
    const host = process.env.REACT_APP_API_BASE_URL;
    const consumerId = this.props.urlData.consumerId;
    const surveyEmailId = this.props.urlData.surveyEmailId;
    const clientId = this.props.urlData.clientId;

    if (clientId === null) {
      if (isGuid(consumerId) && isGuid(surveyEmailId)) {
        const body = {};
        const headers = {
          "DAS-Guest-Subscription-Key":
            process.env.REACT_APP_API_SUBSCRIPTION_KEY,
        };

        await axios
          .post(
            `${host}/enablepartnershare?ConsumerId=${consumerId}&SurveyEmailId=${surveyEmailId}&EnablePartnerShare=${enablePartnerShare}`,
            body,
            { headers }
          )
          .then((res) => {})
          .catch((error) => {
            console.log(error);
          });
      }
    }

    $("#loaderSpinnerEnablePartnerShare").hide();
  }

  componentDidMount() {
    window.onpopstate = () => {
      if (this.state.donePageClick === false) {
        this.state.navigate(0);
      } else {
        this.setState({
          donePageClick: false,
        });
      }
    };

    if (this.state.reviewSites.length === 0) {
      this.GetDealerSites();
      this.GetEnablePartnerShareInfo();
    } else {
      if (
        Array.isArray(this.state.reviewSites) &&
        this.state.reviewSites.length
      ) {
        const dealerData = this.state.reviewSites.filter(function (el) {
          return el.name === "Facebook";
        });

        this.setState({
          chkTerms: true,
          reviewSites: dealerData,
        });
      }
    }
  }

  render() {
    return (
      <div className="survey-menu-container">
        <div id="loaderSpinnerEnablePartnerShare" className="loader"></div>
        <table width="100%" height="50px">
          <tbody>
            <tr>
              <td>
                <p className="text-question-bold">Thank You!</p>
              </td>
              <td>
                <span className="survey-pagination">
                  <img
                    src={surveyPagination}
                    alt="Progress bar.  Page 2 of 3."
                  ></img>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="survey-main-description">
          {this.state.reviewSites.length > 0 ? (
            <span>
              <p>Thank you for taking the time to complete this survey.</p>
              <p>
                We invite you to "like" us or "follow us" on{" "}
                {this.state.sitesContent}. We offer frequent discounts and
                exclusive coupons for our followers. Use the links below to
                connect directly to our pages.
              </p>
              <br></br>
            </span>
          ) : (
            <p
              style={{
                margin: "0px",
                padding: "0px",
              }}
            >
              Thank you for taking the time to complete this survey.
            </p>
          )}
          <div
            style={{
              textAlign: "left",
              paddingLeft: "0px",
            }}
          >
            <table cellPadding="0px" cellSpacing="0px" border="0px">
              <tbody>
                <tr>
                  {this.state.reviewSites.map((reviewSite) => {
                    return (
                      <td key={Math.random()}>
                        <table cellPadding="0px" cellSpacing="0px" border="0px">
                          <tbody>
                            <tr>
                              <td className="thank-you-page-site-reviewsite">
                                <a
                                  rel="noopener noreferrer"
                                  href="#/"
                                  onClick={() =>
                                    this.ClickOnReviewSite(
                                      reviewSite.reviewSiteId,
                                      reviewSite.redirectUrl
                                    )
                                  }
                                >
                                  <img
                                    src={reviewSite.siteImgUrl}
                                    border="0"
                                    alt={
                                      "Click below to share your review on " +
                                      reviewSite.reviewSiteName
                                    }
                                  ></img>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          </div>
          <br></br>
          <div className="thank-you-page-terms-div">
            <table width="100%" height="50px">
              <tbody>
                <tr>
                  <td valign="top" align="center" width="4%">
                    <Form.Check
                      type="checkbox"
                      id="terms-checkbox"
                      defaultChecked={this.state.chkTerms}
                      onChange={this.handleChangeChk}
                    />
                  </td>
                  <td width="96%">
                    <p>
                      By leaving this box checked, you agree to let us share
                      your feedback with our trusted third party partners. Click{" "}
                      <TermsModal /> to learn more.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
