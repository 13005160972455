import React, { Component } from "react";
import dasLogo from "../assets/images/das-logo-black.png";
import wzImage from "../assets/images/das-wiz-w-bg.png";

export default class SurveyNotFound extends Component {
  render() {
    return (
      <div
        style={{
          textAlign: "center",
          height: "auto",
        }}
      >
        <div
          style={{
            fontFamily: "sans-serif",
            fontSize: "35px",
            fontWeight: "600",
          }}
        >
          404 - Survey Not Found.
        </div>
        <span>
          <img src={wzImage} alt="Das-Wizard"></img>
        </span>
        <div
          style={{
            marginBottom: "10px",
          }}
        >
          <span>
            <img src={dasLogo} alt="Das-Logo"></img>
          </span>
        </div>
      </div>
    );
  }
}
