import React, { Component } from "react";
import axios from "axios";
import surveyPagination from "../assets/images/survey-pagination-2-2.png";
import { isGuid } from "../utils/utils";

export default class ThankYouPageNegative extends Component {
  constructor(props) {
    super(props);

    window.scrollTo(0, 0);

    this.state = {
      navigate: this.props.urlData.navigate,
      cId: this.props.urlData.consumerId,
      seId: this.props.urlData.surveyEmailId,
      type: this.props.urlData.type,
      clientId: this.props.urlData.clientId,
      customerFirstName: this.props.urlData.customerFirstName,
      dealerName: this.props.urlData.dealerName,
      reviewSites: this.props.urlData.reviewSitesNegative,
      surveyNegativeThankYouPageText:
        this.props.urlData.surveyNegativeThankYouPageText,
      thankYouPageClick: false,
    };
  }

  async ClickOnReviewSite(reviewSiteId, redirectUrl) {
    const host = process.env.REACT_APP_API_BASE_URL;
    const consumerId = this.props.urlData.consumerId;
    const surveyEmailId = this.props.urlData.surveyEmailId;
    const clientId = this.props.urlData.clientId;

    if (isGuid(consumerId) && isGuid(surveyEmailId)) {
      this.setState({
        thankYouPageClick: true,
      });

      const body = {};
      const headers = {
        "DAS-Guest-Subscription-Key":
          process.env.REACT_APP_API_SUBSCRIPTION_KEY,
      };

      await axios
        .post(
          `${host}/clickonreviewsite?ConsumerId=${consumerId}&SurveyEmailId=${surveyEmailId}&ReviewSiteId=${reviewSiteId}&ClientId=${clientId}`,
          body,
          { headers }
        )
        .then((res) => {
          setTimeout(function () {
            window.open(redirectUrl).focus();
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  componentDidMount() {
    window.onpopstate = () => {
      if (this.state.thankYouPageClick === false) {
        this.state.navigate(0);
      } else {
        this.setState({
          thankYouPageClick: false,
        });
      }
    };

    if (this.state.customerFirstName === "" || this.state.dealerName === "") {
      this.GetSurveyInfo();
    }

    if (this.state.reviewSites.length === 0) {
      this.GetDealerSitesNegative();
    }
  }

  async GetSurveyInfo() {
    const host = process.env.REACT_APP_API_BASE_URL;
    const consumerId = this.props.urlData.consumerId;
    const surveyEmailId = this.props.urlData.surveyEmailId;

    await axios
      .get(
        `${host}/surveyinfo?ConsumerId=${consumerId}&SurveyEmailId=${surveyEmailId}`,
        {
          headers: {
            "DAS-Guest-Subscription-Key":
              process.env.REACT_APP_API_SUBSCRIPTION_KEY, //the guest subscription key
          },
        }
      )
      .then((res) => {
        const surveyResponseData = res.data;

        this.setState({
          customerFirstName: surveyResponseData.customerFirstName,
          customerEmail: surveyResponseData.customerEmail,
          dealerName: surveyResponseData.dealerName,
          transactionType: surveyResponseData.transactionType,
          surveyNegativeThankYouPageText:
            surveyResponseData.surveyNegativeThankYouPageText,
        });

        this.GetSurveyResponseInfo();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async GetSurveyResponseInfo() {
    const host = process.env.REACT_APP_API_BASE_URL;
    const consumerId = this.props.urlData.consumerId;
    const surveyEmailId = this.props.urlData.surveyEmailId;
    const clientId = this.props.urlData.clientId;

    await axios
      .get(
        `${host}/surveyresponseinfo?ConsumerId=${consumerId}&SurveyEmailId=${surveyEmailId}&ClientId=${clientId}`,
        {
          headers: {
            "DAS-Guest-Subscription-Key":
              process.env.REACT_APP_API_SUBSCRIPTION_KEY, //the guest subscription key
          },
        }
      )
      .then((res) => {
        const surveyResponseInfoData = res.data;

        this.setState({
          comments: surveyResponseInfoData.surveyResponseText,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async GetDealerSitesNegative() {
    const host = process.env.REACT_APP_API_BASE_URL;
    const consumerId = this.props.urlData.consumerId;
    const surveyEmailId = this.props.urlData.surveyEmailId;
    const clientId = this.props.urlData.clientId;

    if (isGuid(consumerId) && isGuid(surveyEmailId)) {
      const headers = {
        "DAS-Guest-Subscription-Key":
          process.env.REACT_APP_API_SUBSCRIPTION_KEY,
      };

      await axios
        .get(
          `${host}/thankyoupagesitesnegative?ConsumerId=${consumerId}&SurveyEmailId=${surveyEmailId}&ClientId=${clientId}`,
          { headers }
        )
        .then((res) => {
          const dealerData = res.data;

          this.setState({
            reviewSites: dealerData,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  render() {
    return (
      <div className="survey-menu-container">
        <table width="100%" height="50px">
          <tbody>
            <tr>
              <td>
                <p className="text-question-bold"></p>
              </td>
              <td>
                <span className="survey-pagination">
                  <img
                    src={surveyPagination}
                    alt="Progress bar.  Page 2 of 2."
                  ></img>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="survey-main-description">
          <span>
            <p>{this.state.customerFirstName},</p>
            <p style={{ whiteSpace: "pre-line" }}>
              {this.state.surveyNegativeThankYouPageText}
            </p>
          </span>
        </div>
        <div className="thankyoupagesites-div">
          <table
            className="table-reviewsites"
            cellPadding="0px"
            cellSpacing="0px"
            border="0px"
          >
            <tbody>
              <tr>
                {this.state.reviewSites.map((reviewSite) => {
                  return (
                    <td key={Math.random()}>
                      <table
                        className="table-reviewsiteselement"
                        cellPadding="0px"
                        cellSpacing="0px"
                        border="0px"
                      >
                        <tbody>
                          <tr>
                            <td className="thank-you-page-site-negative-header">
                              Review Site
                            </td>
                          </tr>
                          <tr>
                            <td className="thank-you-page-site-reviewsite">
                              <a
                                rel="noopener noreferrer"
                                href="#/"
                                onClick={() =>
                                  this.ClickOnReviewSite(
                                    reviewSite.reviewSiteId,
                                    reviewSite.redirectUrl
                                  )
                                }
                              >
                                <img
                                  src={reviewSite.siteImgUrl}
                                  border="0"
                                  alt={
                                    "Click below to share your review on " +
                                    reviewSite.reviewSiteName
                                  }
                                ></img>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
