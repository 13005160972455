import React, { Component } from "react";
import axios from "axios";
import { isGuid } from "../utils/utils";

export default class HeaderLogo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientId: "",
      dealerName: "",
      dealerWebsite: "",
      dealerImgUrl: "",
      dealerAddress: "",
      headerGraphic: "",
    };
  }

  async GetDealer() {
    const host = process.env.REACT_APP_API_BASE_URL;
    const consumerId = this.props.urlData.consumerId;
    const surveyEmailId = this.props.urlData.surveyEmailId;
    const clientId = this.props.urlData.clientId;
    let openSurveyReminder = this.props.urlData.osr;

    if (isGuid(consumerId) && isGuid(surveyEmailId)) {
      if (openSurveyReminder === null) {
        openSurveyReminder = false;
      } else {
        openSurveyReminder = true;
      }

      await axios
        .get(
          `${host}/accountinfo?ConsumerId=${consumerId}&SurveyEmailId=${surveyEmailId}&ClientId=${clientId}&OpenedSurveyReminder=${openSurveyReminder}`,
          {
            headers: {
              "DAS-Guest-Subscription-Key":
                process.env.REACT_APP_API_SUBSCRIPTION_KEY, //the guest subscription key.
            },
          }
        )
        .then((res) => {
          const dealerData = res.data;
          var imgUrl = "";

          if (dealerData.headerGraphic === "graphic") {
            imgUrl = dealerData.headerGraphicImgUrl;
          }

          this.setState({
            clientId: dealerData.clientId,
            dealerName: dealerData.name,
            dealerWebsite: dealerData.website,
            dealerImgUrl: imgUrl,
            dealerAddress: dealerData.dealerAddress,
            headerGraphic: dealerData.headerGraphic,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  componentDidMount() {
    //Call the function to get the dealer information
    this.GetDealer();
  }

  render() {
    return (
      <div className="header-logo-image-container">
        <div>
          {this.state.headerGraphic === "graphic" ? (
            <img
              className="header-logo-image"
              src={this.state.dealerImgUrl}
              alt=""
            ></img>
          ) : this.state.headerGraphic === "default" ? (
            <table
              style={{
                backgroundColor: "#D7D7D7",
                width: "100%",
                maxWidth: "800px",
                height: "100px",
                cellSpacing: "0px",
                cellPadding: "0px",
              }}
            >
              <tbody>
                <tr>
                  <td
                    style={{
                      padding: "0px",
                      paddingTop: "3px",
                    }}
                  >
                    <table
                      style={{
                        width: "100%",
                        cellSpacing: "0px",
                        cellPadding: "0px",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              verticalAlign: "middle",
                              width: "100%",
                              paddingLeft: "20px",
                              textAlign: "left",
                              fontFamily: "Verdana",
                              fontSize: "16pt",
                              color: "#000000",
                              fontWeight: "bold",
                            }}
                          >
                            <div>{this.state.dealerName}</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      verticalAlign: "middle",
                      width: "100%",
                      paddingLeft: "20px",
                      paddingTop: "5px",
                      paddingBottom: "10px",
                      textAlign: "left",
                      fontFamily: "Verdana",
                      fontSize: "10pt",
                      color: "#000000",
                      fontWeight: "bold",
                    }}
                  >
                    <div>{this.state.dealerAddress}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    );
  }
}
