import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function TermsModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };

  return (
    <>
      <a href="/#" onClick={handleShow} id="lnkTermsModal">
        Terms of use
      </a>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Terms of Use</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontWeight: "bold" }}>
            By checking this box, you acknowledge the following:
          </p>
          <ol>
            <li className="terms-list-item">
              That you are providing and granting a royalty free, perpetual,
              irrevocable and fully transferable and sublicense and your consent
              for the dealership, or their agents or representatives, at their
              own discretion to republish any or all survey content including
              the potential of publishing on websites managed by the dealership;
              your name will be displayed as first name and last initial only.
            </li>
            <li className="terms-list-item">
              That you are at least 18 years or older, have personal knowledge
              of the dealership based on bona fide vehicle sales and service
              business with the dealership, and that the you have not been
              compensated for this survey in any manner and that you have no
              relationship or affiliation with the dealership;
            </li>
            <li className="terms-list-item">
              That your survey response is accurate and honest, that you are
              responsible for the content of the response, and that the content
              may be edited prior to posting if it is determined that the
              content is obscene, lewd, lascivious, filthy, excessively violent,
              harassing, or otherwise objectionable;
            </li>
            <li className="terms-list-item">
              That the content you are submitting complies with all applicable
              laws and regulations and does not contain any personal information
              for anyone other than you. You agree to fully defend, indemnify,
              and hold the dealership and its officers, directors, employees,
              shareholders, customers, agents, successors and assigns harmless
              from and against any and all loss, damage, costs, claims,
              expenses, settlements or other liability (including reasonable
              attorneys’ fees) arising from an event in which the content you
              submit does not comply with these laws and regulations.
            </li>
          </ol>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TermsModal;
