import React, { Component } from "react";
import { isGuid } from "../utils/utils";
import axios from "axios";
import $ from "jquery";

export default class Unsubscribe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dealerName: "",
      customerEmail: "",
      reviewSites: [],
      clientId: this.props.urlData.clientId,
    };
  }

  async GetSurveyInfo() {
    $("#loaderSpinnerUnsubscribe").show();
    const host = process.env.REACT_APP_API_BASE_URL;
    const consumerId = this.props.urlData.consumerId;
    const surveyEmailId = this.props.urlData.surveyEmailId;

    await axios
      .get(
        `${host}/surveyinfo?ConsumerId=${consumerId}&SurveyEmailId=${surveyEmailId}`,
        {
          headers: {
            "DAS-Guest-Subscription-Key":
              process.env.REACT_APP_API_SUBSCRIPTION_KEY, //the guest subscription key
          },
        }
      )
      .then((res) => {
        const surveyResponseData = res.data;

        this.setState({
          customerEmail: surveyResponseData.customerEmail,
          dealerName: surveyResponseData.dealerName,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async GetDealerSites() {
    const host = process.env.REACT_APP_API_BASE_URL;
    const consumerId = this.props.urlData.consumerId;
    const surveyEmailId = this.props.urlData.surveyEmailId;
    const clientId = this.props.urlData.clientId;

    if (isGuid(consumerId) && isGuid(surveyEmailId)) {
      const headers = {
        "DAS-Guest-Subscription-Key":
          process.env.REACT_APP_API_SUBSCRIPTION_KEY,
      };

      await axios
        .get(
          `${host}/donepagesites?ConsumerId=${consumerId}&SurveyEmailId=${surveyEmailId}&ClientId=${clientId}`,
          { headers }
        )
        .then((res) => {
          this.setState({
            reviewSites: res.data.reviewSiteList,
            sitesContent: res.data.sitesContent,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  async ClickOnReviewSite(reviewSiteId, redirectUrl) {
    const host = process.env.REACT_APP_API_BASE_URL;
    const consumerId = this.props.urlData.consumerId;
    const surveyEmailId = this.props.urlData.surveyEmailId;
    const clientId = this.props.urlData.clientId;

    if (isGuid(consumerId) && isGuid(surveyEmailId)) {
      const body = {};
      const headers = {
        "DAS-Guest-Subscription-Key":
          process.env.REACT_APP_API_SUBSCRIPTION_KEY,
      };

      await axios
        .post(
          `${host}/clickonreviewsite?ConsumerId=${consumerId}&SurveyEmailId=${surveyEmailId}&ReviewSiteId=${reviewSiteId}&ClientId=${clientId}`,
          body,
          { headers }
        )
        .then((res) => {
          setTimeout(function () {
            window.open(redirectUrl).focus();
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  async SubmitUnsubscribedRequest() {
    const host = process.env.REACT_APP_API_BASE_URL;
    const consumerId = this.props.urlData.consumerId;
    const surveyEmailId = this.props.urlData.surveyEmailId;

    if (isGuid(consumerId) && isGuid(surveyEmailId)) {
      const body = {};
      const headers = {
        "DAS-Guest-Subscription-Key":
          process.env.REACT_APP_API_SUBSCRIPTION_KEY,
      };

      await axios
        .post(
          `${host}/unsubscribe?ConsumerId=${consumerId}&SurveyEmailId=${surveyEmailId}`,
          body,
          { headers }
        )
        .then((res) => {})
        .catch((error) => {
          console.log(error);
        });
    }
    $("#loaderSpinnerUnsubscribe").hide();
  }

  componentDidMount() {
    window.onpopstate = () => {
      this.state.navigate(0);
    };
    this.GetSurveyInfo();
    this.GetDealerSites();
    this.SubmitUnsubscribedRequest();
  }

  render() {
    return (
      <div className="survey-menu-container">
        <div id="loaderSpinnerUnsubscribe" className="loader"></div>
        <div className="survey-main-description">
          <span>
            <p style={{ fontWeight: "bold" }}>Unsubscribe Successful</p>
            <p>
              {this.state.customerEmail} has successfully been unsubscribed from
              future survey requests from {this.state.dealerName}.
            </p>
            {this.state.reviewSites.length > 0 ? (
              <p>
                We offer frequent discounts and exclusive coupons to our
                followers. We invite you to "like" us or "follow" us on{" "}
                {this.state.sitesContent}. Use the links below to connect
                directly to our pages.
              </p>
            ) : (
              <p></p>
            )}
          </span>
          <br></br>
          <div
            style={{
              textAlign: "left",
              paddingLeft: "0px",
            }}
          >
            <table cellPadding="0px" cellSpacing="0px" border="0px">
              <tbody>
                <tr>
                  {this.state.reviewSites.map((reviewSite) => {
                    return (
                      <td key={Math.random()}>
                        <table cellPadding="0px" cellSpacing="0px" border="0px">
                          <tbody>
                            <tr>
                              <td className="thank-you-page-site-reviewsite">
                                <a
                                  rel="noopener noreferrer"
                                  href="#/"
                                  onClick={() =>
                                    this.ClickOnReviewSite(
                                      reviewSite.reviewSiteId,
                                      reviewSite.redirectUrl
                                    )
                                  }
                                >
                                  <img
                                    src={reviewSite.siteImgUrl}
                                    border="0"
                                    alt={
                                      "Click below to share your review on " +
                                      reviewSite.reviewSiteName
                                    }
                                  ></img>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          </div>
          <br></br>
        </div>
      </div>
    );
  }
}
