export function isGuid(stringToTest) {
  if (stringToTest !== null && stringToTest[0] === "{") {
    stringToTest = stringToTest.substring(1, stringToTest.length - 1);
  }
  var regexGuid =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regexGuid.test(stringToTest);
}

export function OpenUrl(url) {
  let anchorElement = document.createElement("a");
  anchorElement.href = url;
  anchorElement.target = "_blank";
  document.body.appendChild(anchorElement);
  anchorElement.click();
  document.body.removeChild(anchorElement);
}
