import React, { Component } from "react";
import axios from "axios";
import surveyPagination from "../assets/images/survey-pagination.png";
import SubmitSurvey from "./submitSurvey";
import TermsModal from "./termsModal";
import Form from "react-bootstrap/Form";
import { Rating } from "react-simple-star-rating";

export default class SurveyMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cId: this.props.urlData.consumerId,
      seId: this.props.urlData.surveyEmailId,
      type: this.props.urlData.type,
      clientId: this.props.urlData.clientId,
      thirdPartySiteToPublish: this.props.urlData.thirdPartySiteToPublish,
      customerFirstName: "",
      handler: this.props.urlData.handler,
      handlerStarRating: this.props.urlData.handlerStarRating,
      handlerSurveyInfo: this.props.urlData.handlerSurveyInfo,
      smartGmailSurvey: false,
      dealerName: "",
      starRating: 5,
      npsScore: "",
      chkTerms: true,
      experienceFeedback: "",
      improveFeedback: "",
      transactionType: "",
      mazdaBrand: false,
      surveyHeaderText: "",
      surveyStarRatingQuestion: "",
      surveyReferralQuestion: "",
      surveyExperienceQuestion: "",
      surveyImproveQuestion: "",
      surveyThankYouPageText: "",
    };

    this.handleRating = (rate) => {
      this.state.handlerStarRating(rate);
      this.setState({
        starRating: rate,
      });
    };

    this.handleNpsScore = (e) => {
      const score = e.target.value;
      this.setState({
        npsScore: score,
      });
    };

    this.handleExperienceFeedback = (e) => {
      const experienceFeedback = e.target.value;
      this.state.handler(experienceFeedback);
      this.setState({
        experienceFeedback: experienceFeedback,
      });
    };

    this.handleImproveFeedback = (e) => {
      const improveFeedback = e.target.value;
      this.setState({
        improveFeedback: improveFeedback,
      });
    };

    this.handleChangeChk = (evt) => {
      this.setState({
        chkTerms: evt.target.checked,
      });
    };
  }

  componentDidMount() {
    this.GetSurveyInfo();
  }

  async GetSurveyInfo() {
    const host = process.env.REACT_APP_API_BASE_URL;
    const consumerId = this.props.urlData.consumerId;
    const surveyEmailId = this.props.urlData.surveyEmailId;
    const clientId = this.props.urlData.clientId;

    await axios
      .get(
        `${host}/surveyinfo?ConsumerId=${consumerId}&SurveyEmailId=${surveyEmailId}&ClientId=${clientId}`,
        {
          headers: {
            "DAS-Guest-Subscription-Key":
              process.env.REACT_APP_API_SUBSCRIPTION_KEY, //the guest subscription key
          },
        }
      )
      .then((res) => {
        const surveyResponseData = res.data;

        this.setState({
          clientId: clientId,
          customerFirstName: surveyResponseData.customerFirstName,
          dealerName: surveyResponseData.dealerName,
          smartGmailSurvey: surveyResponseData.smartGmailSurvey,
          transactionType: surveyResponseData.transactionType,
          thirdPartySiteToPublish:
            surveyResponseData.surveyThirdPartySiteToPublish,
          surveyHeaderText: surveyResponseData.surveyHeaderText,
          surveyStarRatingQuestion: surveyResponseData.surveyStarRatingQuestion,
          surveyReferralQuestion: surveyResponseData.surveyReferralQuestion,
          surveyExperienceQuestion: surveyResponseData.surveyExperienceQuestion,
          surveyImproveQuestion: surveyResponseData.surveyImproveQuestion,
          surveyThankYouPageText: surveyResponseData.surveyThankYouPageText,
          surveyNegativeThankYouPageText:
            surveyResponseData.surveyNegativeThankYouPageText,
        });

        this.state.handlerSurveyInfo(
          surveyResponseData.customerFirstName,
          surveyResponseData.customerEmail,
          surveyResponseData.dealerName,
          surveyResponseData.transactionType,
          surveyResponseData.surveyThirdPartySiteToPublish,
          surveyResponseData.surveyThankYouPageText,
          surveyResponseData.surveyNegativeThankYouPageText,
          surveyResponseData.smartGmailSurvey
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <div className="survey-menu-container">
        <table width="100%" height="50px">
          <tbody>
            <tr>
              <td>
                <p className="text-question-bold">How did we do?</p>
              </td>
              <td>
                <span className="survey-pagination">
                  <img
                    src={surveyPagination}
                    alt="Progress bar.  Page 1 of 3."
                  ></img>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="survey-main-description">
          <span>
            <p>{this.state.customerFirstName},</p>
            <p style={{ whiteSpace: "pre-line" }}>
              {this.state.surveyHeaderText}
            </p>
          </span>
          <span>
            <p>These 4 questions should only take a minute to answer.</p>
          </span>
        </div>
        <div id="survey-questions">
          <ol>
            <li>
              <div className="control-group">
                <p className="question-text" style={{ whiteSpace: "pre-line" }}>
                  {this.state.surveyStarRatingQuestion}
                  &nbsp;
                  <font color="red">*</font>&nbsp;
                </p>
                <div className="rating-star-div">
                  <Rating
                    id="starRatingSelector"
                    onClick={this.handleRating}
                    initialValue="5"
                    size="30"
                  />
                </div>
              </div>
            </li>
            <li>
              <div>
                <p className="question-text" style={{ whiteSpace: "pre-line" }}>
                  {this.state.surveyReferralQuestion}&nbsp;
                  <font color="red">*</font>&nbsp;
                </p>
                <div className="nps-score-dropdown-div">
                  <Form.Select
                    id="npsScoreDropDown"
                    size="sm"
                    style={{ fontSize: "13px" }}
                    onChange={this.handleNpsScore}
                  >
                    <option value="">Please select a number</option>
                    <option value="10">10 - Definitely Would Recommend</option>
                    <option value="9">9</option>
                    <option value="8">8</option>
                    <option value="7">7</option>
                    <option value="6">6</option>
                    <option value="5">5 - Neutral</option>
                    <option value="4">4</option>
                    <option value="3">3</option>
                    <option value="2">2</option>
                    <option value="1">
                      1 - Definitely Would Not Recommend
                    </option>
                  </Form.Select>
                </div>
              </div>
            </li>
            <li>
              <p className="question-text" style={{ whiteSpace: "pre-line" }}>
                {this.state.surveyExperienceQuestion}&nbsp;
                <font color="red">*</font>&nbsp;
              </p>
              <div className="experience-text-div">
                <Form.Group className="mb-3" controlId="txtExperience">
                  <Form.Control
                    size="sm"
                    as="textarea"
                    rows={3}
                    style={{
                      fontWeight: "normal",
                      fontSize: "13px",
                    }}
                    onChange={this.handleExperienceFeedback}
                  />
                </Form.Group>
              </div>
            </li>
            <li>
              <p className="question-text" style={{ whiteSpace: "pre-line" }}>
                {this.state.surveyImproveQuestion}
              </p>
              <div className="improve-text-survey-main-div">
                <Form.Group className="mb-3" controlId="txtImprove">
                  <Form.Control
                    size="sm"
                    as="textarea"
                    rows={3}
                    style={{
                      fontWeight: "normal",
                      fontSize: "13px",
                    }}
                    onChange={this.handleImproveFeedback}
                  />
                </Form.Group>
              </div>
            </li>
          </ol>
        </div>
        <div>
          <table width="100%" height="50px">
            <tbody>
              <tr>
                <td valign="top" align="center" width="4%">
                  <Form.Check
                    type="checkbox"
                    id="terms-checkbox"
                    defaultChecked={this.state.chkTerms}
                    onChange={this.handleChangeChk}
                  />
                </td>
                <td width="96%">
                  <p>
                    By filling out this survey you agree to allow all comments
                    submitted, as well as first name and last initial, to be
                    used in marketing materials if applicable as outlined in the{" "}
                    <TermsModal /> and{" "}
                    <a
                      className="privacy_policy"
                      target="_blank"
                      rel="noreferrer"
                      href="https://digitalairstrike.com/privacy/"
                    >
                      Privacy Policy
                    </a>
                    .
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <SubmitSurvey props={this.state} />
        </div>
        <div
          style={{
            textAlign: "right",
          }}
        >
          <span
            style={{
              marginTop: "15px",
            }}
          >
            <font color="red">*</font>&nbsp;Required
          </span>
        </div>
      </div>
    );
  }
}
